export class BinaryBox {
    private HEIGHT_ADJ: number = 0.10;
    private WIDTH_ADJ: number = 0.10;
    private WIDTH_THRESH: number = 0.35;
    private container: HTMLElement;
    private height: number;
    private width: number;

    constructor(container: HTMLElement) {
        this.container = container;
        this.update();
    }

    update() {
        this.height = this.container.clientHeight;
        this.width = this.container.clientWidth;
        this.container.innerHTML = "";

        this.render(this.container);
    }

    private render(container: HTMLElement) {
        var numLines = this.height * this.HEIGHT_ADJ;
        var numChars = this.width * this.WIDTH_ADJ;
        var maxChars = this.width * this.WIDTH_THRESH;
        var firstLineLen = Math.min(numChars * .25, maxChars);

        var lineCount = 0;
        var htmlArray: string[] = [];
        while (++lineCount <= numLines) {
            var charCount = 0;
            var numArray: number[] = [];
            htmlArray.push("<div>");
            while (++charCount <= lineCount + firstLineLen) {
                numArray.push(charCount % 2 == 0 ? 1 : 0);
            }
            htmlArray.push(numArray.join(""));
            htmlArray.push("</div>");
        }

        container.innerHTML = htmlArray.join("");
    }
}